.test-upload-page {
  background-color: rgba(44, 44, 44, 0.9);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.test-upload aside {
  /* margin-right: 1.5em; */
  padding-right: 1.5em;
  margin: 0 !important;
}

.test-upload-page .crop-image {
  height: 100%;
}

/* .test-upload main {
  z-index: 3;
  display: grid;
  grid-template-rows: auto 4em;
  grid-template-columns: auto 4fr 1fr;
  grid-template-areas:
  'a b .'
  'd d d';
  height: calc(100% - 9.5em);
} */

.test-upload aside {
  z-index: 3;
  grid-area: a;
}

.test-upload-content {
  grid-area: b;
  /* width: 100%; */
  height: calc(100% - 9.5em);
}

.test-upload-page .bottom-buttons {
  display: flex;
  width: calc(100% - 3em);
  padding-bottom: 0 !important;
  /* grid-area: d; */
  position: absolute;
  bottom: 1.5em;
  left: 1.5em;
}

.test-upload-right {
  margin: 0 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 4.5em;
}

.test-upload-right .location-p {
  color: white;
  text-transform: capitalize;
  font-size: 1.5em;
  margin: 0.5em 0;
}

.test-upload-right p:first-child {
  color: #dfdfdf;
  margin: 0;
}

.test-upload-cropperContainer .imageContainer {
  height: 100%;
}

/* .location-container {
  height: 10em;
} */

.location-preview {
  height: 12em;
  width: auto;
}

.size-warning {
  background: black;
  padding: 0.5em;
}

.size-warning p, .size-warning span {
  color: white;
}

.size-warning .yellow {
  color: yellow;
}
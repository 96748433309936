.mole-tracking-test-page main {
  min-width: 62.5em;
  display: grid;
  max-width: 155vh;
  grid-template-rows: minmax(0, 1fr) 4em;
  /* grid-template-columns: auto minmax(0, 3fr) 2fr; */
  grid-template-columns: min-content minmax(0, 65vh) auto;
  grid-template-areas:
  'a b c'
  'd e f';
  /* overflow: scroll !important;
  position: fixed !important; */
}

/* .mole-tracking-test-page #first-screen {
  grid-template-rows: minmax(0, 1fr) max-content !important;
} */

.mole-tracking-test-page #first-screen {
  max-width: 155vh;
  min-height: 34em;
  grid-template-rows: max-content auto !important;
}

.mole-tracking-test-page .test-page aside {
  grid-area: a;
}

.mole-tracking-test-page .lesion-div {
  grid-area: b;
  display: inline-block;
  margin-right: 1.5em;
  min-width: 8em;
  position: relative;
}


.mole-tracking-test-page .moleTrackingCarousel {
  grid-area: e;
  bottom: 0px;
  width: 100%;
  padding-top: 1em;
  padding-right: 1.5em;
  box-sizing: border-box;

}

.mole-tracking-test-page .moleTrackingCarousel-image-nav {
  height: 3em;
  width: 100%;
  display: flex;
  margin-right: 1.5em;
  justify-content: center;
  margin-bottom: 0.5em
}


.mole-tracking-test-page .moleTrackingCarousel-images {
  padding-top: 3px;
  font-size: 1.5em;
  display: flex;
  flex-direction: row;
  height: 100%;
  cursor: pointer;
}

.moleTrackingCarousel-images-border {
  font-size: 1.5em;
  display: flex;
  flex-direction: row;
  height: 100%;
  border-style: solid;
}

.moleTrackingCarousel-nav {
  font-size: 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-left: 0.75em; */
  box-sizing: border-box;
  /* position: absolute; */
  width: 100%;
  height: 1.5em;
  bottom: 0;
}

.moleTrackingCarousel-middle {
  max-width: calc(100% - 6em - 20.75px);
  overflow-x: scroll;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.moleTrackingCarousel-image-nav img {
  box-sizing: border-box;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.moleTrackingCarousel-middle::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.moleTrackingCarousel-middle {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.moleTrackingCarousel .arrow-div-first {
  display: flex;
  justify-content: flex-start;
}

.moleTrackingCarousel .arrow-div-last {
  display: flex;
  justify-content: flex-end;
}

.moleTrackingCarousel-nav p {
  margin: 0.5em 0;
}

.moleTrackingCarousel-nav div {
  width: 2.5em;
}

.mole-tracking-test-page .results-notes-div {
  grid-area: c;
  display: grid;
  grid-template-rows: max-content auto;
  height: 100%;
  overflow: scroll;
}

.mole-tracking-test-page .contact-button-div {
  padding-top: 1em;
  grid-area: d;
  /* z-index: 2; */
}

.mole-tracking-test-page .results-div {
  background-color: white;
  padding: 0.5em;
}

.mole-tracking-test-page .results-div p {
  margin: 0.5em 0;
}

.mole-tracking-test-page .notes-div {
  display: grid;
  grid-template-rows: max-content auto;
}

.mole-tracking-test-page .patient-notes-div {
  grid-template-rows: max-content max-content auto;
  margin-bottom: 1em;
}

.mole-tracking-test-page .notes-div img, .message-div img {
  height: 1.5em;
  width: auto;
}

.mole-tracking-test-page .notes-div p {
  margin: 0;
}

.mole-tracking-test-page .notes-div > div {
  /* padding: 0.5em 0; */
  padding: 0 0 0.5em;
}

.mole-tracking-test-page .notes-div > div:first-child {
  /* padding: 0.5em 0; */
  margin-top: 0.5em;
}

.mole-tracking-test-page .test-page textarea {
  max-height: 50em;
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  border: none;
  background-color: white;
  -webkit-appearance: none !important;
  border-radius: 0 !important;
  resize: none;
}

.mole-tracking-test-page .isTyping:focus {
  outline-color: black;
}

.mole-tracking-test-page .isNotTyping:focus {
  outline-color: #00D44C;
}

.mole-tracking-physician-notes {
  height: 10em;
}

.mole-tracking-test-page .conditions-div p {
  font-size: 0.75em;
}

.mole-tracking-test-page .conditions-div > div:first-child p {
  font-size: 1em !important;
}

.mole-tracking-test-page .test-page .patient-notes {
  height: 10em;
  overflow-y: scroll;
  margin-bottom: 0.5em;
  /* background: white; */
}

.mole-tracking-test-page .test-page .patient-tracking-notes {
  height: 10em;
  overflow-y: scroll;
  margin-bottom: 0.5em;
  /* background: white; */
}

@media only screen and (max-width: 1200px) {
  .mole-tracking-test-page main {
    grid-template-columns: min-content minmax(0, auto) auto;
  }
}
.dashboard main, .m-proc main {
  display: flex;
}

.dashboard .patient-list, .m-proc .patient-list {
  width: calc(100% - 18.4em) /* aside width + 1.5em margin */
}

.floating-button-container {
  position: fixed;
  right: 0;
  top: 9em;
  padding: 2.1em;
  padding-right: 1.5em;
}

.dashboard header, .m-proc header {
  position: fixed;
  z-index: 2;
}


/*  accommodate only patients scrolling and
    "awaiting follow up bubble" on patient thumb
*/

.dashboard aside, .m-proc aside {
  margin-top: 1.5em;
}

.dashboard .patient-list, .m-proc .patient-list {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  position: absolute;
  height: 100%;
  overflow: visible;
  /* position: fixed; */
  /* left: 30.5em; */
  left: 19em;
  overflow: visible;
}

.dashboard .patients, .m-proc .patients {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  height: 100%;
  overflow: scroll;
  padding-top: 1.5em;
  /* position: absolute;
  left: 18em; */
}

.dashboard .patients a {
  margin-top: 3em !important;
}

.patients::-webkit-scrollbar {
  display: none;
}

.dashboard main, .m-proc main {
  padding-top: 0;
}

.dashboard aside, .m-proc aside {
  position: fixed;
  left: 14em;
  z-index: 2;
  /* position: absolute;
  left: 1.5em; */
}
.contact-us-content {
  display: flex;
  width: 100%;
  height: 100%;
}

.contact-us-content form:first-child {
  height: 50%;
}

.contact-us-content form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 1.5em;
  width: 50%;
}

.contact-us-content form:last-child {
  height: 100%;
}

.contact-us-content form > p {
  margin: 0 0 1em;
}

.contact-us-content textarea {
  min-height: 10em;
}
.billing-content {
  height: 100%;
  width: 100%;
  display: grid;
  grid-gap: 1.5em;
  grid-template-columns: repeat(12, minmax(2em, 1fr));
  grid-template-rows: 3fr max-content 3em;
  grid-template-areas:
  'a a a b b b c c c c c c'
  'd d d d d d e e e e e e'
  '. . . . . . . . . . . .';
}

.choose-plan {
  grid-template-areas:
  'f f f g g g g g g g g g'
  'd d d d d d . . . . . .'
  'x x x x x x x x x x x x'
}

.cancel-btn-container {
  grid-area: x;
}

.billing-content .top {
  height: 75%;
  margin-bottom: 1.5em;
}

.billing-content .bottom {
  height: 25%;
}

.billing-content .version-card, .billing-content .current-plan, .billing-content .plan-card {
  border: 1px solid black;
  background: white;
  padding: 1em;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
}

.billing-content .small-version-card {
  border: 1px solid black;
  background: white;
  padding: 1em;
  box-sizing: border-box;
  width: 100%;
  height: 50%;
  margin-bottom: 0.5em;
}

.monthly-yearly-buttons {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 50%;
}

.billing-content .plan-card {
  width: calc(33.3% - 0.66em);
}

.billing-content .version-card {
  grid-area: a;
  /* border-right: none; */
}

.billing-content .version-card img, .small-version-card img {
  height: 3em;
  width: auto;
}

.billing-content .version-card h2, .small-version-card h2 {
  font-size: 1.25em;
}

.billing-content .current-plan {
  grid-area: b;
}

.billing-content .usage-and-creditCard {
  grid-area: c;
  height: 100%;
  width: calc(100% - 1em);
  box-sizing: border-box;
}

.billing-content .usage {
  padding: 1em;
  box-sizing: border-box;
  border: 1px solid black;
  border-radius: 1em;
  border-top: none;
  border-bottom: none;
  height: calc(100% - 6.8em);
}

.billing-content .usage-and-creditCard h2 {
  margin: 0;
  font-size: 1em;
  text-transform: uppercase;
}

.billing-content h2 {
  font-weight: normal;
}

.billing-content .promo-container {
  grid-area: d;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  margin-top: 1em;
}

.billing-content .payment-container {
  grid-area: e;
  box-shadow: inset 0 0 30px 0 #FFFFFF, inset -5px -5px 10px 0 rgba(255,255,255,0.5), -10px -10px 20px 0 rgba(255,255,255,0.5), 0 5px 11px -1px rgba(191,191,191,0.5), -5px -5px 12px 1px rgba(255,255,255,0.5), 10px 10px 20px 0 rgba(210,210,210,0.5);
  padding: 1em;
  box-sizing: border-box;
  border-radius: 1em;
  width: 100%;
}

.billing-content .payment-container > div:first-child {
  display: flex;
  justify-content: space-between;
}

.billing-content .payment-container span {
  color: #a5a5a5;
  cursor: pointer;
}

.billing-content .payment-container p, .payment-container h2 {
  margin: 0;
}

.billing-content .user-message-container {
  height: 1.5em;
}

.credit-card {
  height: 1.5em;
  border: 1px solid black;
  border-radius: 0.5em;
  padding: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: inset 0 0 30px 0 #FFFFFF, inset -5px -5px 10px 0 rgba(255,255,255,0.5), -10px -10px 20px 0 rgba(255,255,255,0.5), 0 5px 11px -1px rgba(191,191,191,0.5), -5px -5px 12px 1px rgba(255,255,255,0.5), 10px 10px 20px 0 rgba(210,210,210,0.5);
  margin: 1em 0 0;
}

.credit-card > div {
  display: flex;
}

.credit-card .gray-div {
  /* height: 100%; */
  width: 2em;
  margin-right: 0.5em;
  border-radius: 0.25em;
  background-color: #d8d8d8;
}

.monthly-yearly-container {
  grid-area: f;
}

.plan-selection-container {
  grid-area: g;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 1.5em;
}

.plan-selection-container .plan-card {
  width: 100%;
}
/* Media queries */
@media only screen and (max-width: 1200px) {
  .billing-content .version-card h2, .small-version-card h2 {
    font-size: 1em;
  }
}
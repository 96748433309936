.full-loading-screen {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(150, 150, 150, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}
.link-div {
  background-color: #F4F4F4;
  box-shadow: -5px -5px 5px 0 rgba(255,255,255,0.5), 0 -2px 4px 0 rgba(255,255,255,0.5), 0 2px 3px 0 rgba(212,212,212,0.5), 5px 5px 5px 0 rgba(202,202,202,0.5);
  padding: 1em;
  margin: 1em 0;
}

.test-link {
  width: calc(100% - 16.5em);
  margin-left: 16.5em;
  margin-top: 1.5em;
}
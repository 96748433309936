.auth-page {
  min-height: 500px;
  height: 100%;
  display: flex;
}

.auth-page .lazarus {
  width: 12em;
  margin-bottom: 1em;
}

.auth-page .dm {
  width: 6em;
  margin-bottom: 1em;
}

.auth-page .underline {
  cursor: pointer;
}

.auth-left {
  background-color: #7EEEFE;
  width: 41%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.auth-left > div {
  padding: 10% 1em 1em 10%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 67%;
  min-height: 200px;
}

.auth-left footer {
  padding: 1em 1em 1em 10%;
  /* height: 33%; */
  height: 15.875em;
  box-sizing: border-box;
}

.auth-left img {
  max-width: 80%;
}

.auth-right {
  width: 59%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.auth-right > div {
  padding: 1em;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
}




.aside-nav {
  background-color: black;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 12.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* padding-top: 0.5em; */
  box-sizing: border-box;
  z-index: 2;
}

.aside-nav p {
  color: white;
  margin: 0;
}

.aside-nav > div {
  width: 100%;
}

.aside-nav img {
  height: 80%;
  width: auto;
  margin-right: 1em;
}

.aside-div, .dm-div {
  display: flex;
  align-items: center;
  width: calc(100% - 2em - 3px); /* account for padding and border */
  height: 2em;
  /* margin-bottom: 1em; */
  box-sizing: content-box;
  padding: 0.5em 1em;
  border-left: 3px solid black;
  /* transition: 0.2s; */
}

.aside-div {
  cursor: pointer;
}

.aside-div:hover, .selected-div {
  border-left: 3px solid white;
  background-color: rgb(26, 26, 26);
}
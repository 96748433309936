.change-password-content {
  width: 50%;
  min-width: 10em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.user-message {
  margin: 0;
  color: #a5a5a5;
}

.change-password-content h1 {
  font-weight: 400;
  margin-top: 0;
}

.change-password-content form {
  width: 100%;
}

.user-message-container {
  height: 4em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.body-part-container {
  height: 100%;
}

.body-part-container .g, .body-part-container .k {
  height: 100%;
}

.body-part-container .cropper-container, .body-part-container .image-container {
  height: 100%;
}

.body-part-container img {
  height: 100%;
  width: auto;
}

.body-part-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  z-index: 2;
  padding: 0.5em;
  box-sizing: border-box;
}

.body-part-container .flip-div {
  height: 2em;
  width: 2em;
  border-radius: 2em;
  background-color: rgb(31, 31, 31);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  box-sizing: border-box;
}

.body-part-container .zoom-button {
  background-color: rgb(74, 74, 74);
  padding: 0.5em;
  box-sizing: border-box;
  margin-bottom: 0.5em;
  border-radius: 0.5em;
  cursor: pointer;
}

.body-part-container .selected-zoom-button {
  background-color: rgb(31, 31, 31);
}

.body-part-container .zoom-button p {
  margin: 0;
  color: white;
}

.crop-image > div {
  height: 100%;
}

.k .ReactCrop__crop-selection {
  background: radial-gradient(rgba(53, 244, 188, 1), rgba(53, 244, 188, 0.5), rgba(53, 244, 188, 0.2), rgba(53, 244, 188, 0), rgba(53, 244, 188, 0)) !important;
  border: 1px solid white;
  box-shadow: none;
  border-radius: 100%;
  z-index: 3;
}

.zoomed-in .ReactCrop__crop-selection {
  background: radial-gradient(rgba(53, 244, 188, 1), rgba(53, 244, 188, 0.3), rgba(53, 244, 188, 0.1), rgba(53, 244, 188, 0), rgba(53, 244, 188, 0), rgba(53, 244, 188, 0)) !important;
  border: 1px solid white;
  box-shadow: none;
  border-radius: 100%;
  z-index: 3;
}

.g {
  position: relative;
}

/* .k .image-container, .k .crop-image {
  height: 100%;
}

.k .image-container {
  box-sizing: border-box;
  padding: 10px 0;
}

.k .crop-image > div:first-child {
  height: 100%;
}

.k .crop-image > div > .ReactCrop__image {
  max-height: calc(100vh - 112px) !important;
  height: 699px;
}

.zoomed-in .ReactCrop__image {
  filter: blur(2px);
} */
.cropperContainer {
  background-color: white;
  top: 50%;
  left: 50%;
  height: 80%;
  width: auto;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 4;
  padding: 1em;
  max-width: 90%;
  max-height: 90%;
}

.test-upload-cropperContainer {
  height: 100%;
  padding: 0;
  position: block;
}

.imageContainer {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  width: auto;
  height: calc(100% - 4em);
  background: black;
}

.buttonContainer {
  margin-top: 1em;
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}

.elAddImageCropper, .elAddImageCropper > div {
  height: 100%;
  /* width: auto; */
}

/* .elAddImageCropper > div {
  overflow: scroll;
} */

.elAddImageCropper > img {
  height: 100%;
  width: auto;
  max-height: 100%;
}

.ReactCrop__image {
  height: 100%;
  width: auto;
  max-width: none !important;
}

.upload-image-label {
  /* Style this like a button */
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
}

.upload-image-label:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

#add-image-div {
  width: 110px;
  height: 110px;
  background-color: #a5a5a5;
  border-radius: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#add-image-div > img {
  width: 100%;
  height: 100%;
}

.buttonContainer > p {
  font-size: 14px;
  cursor: pointer;
  border: none;
  width: 30%;
  transition: 0.3s;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 16px;
}
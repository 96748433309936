.settings-page .link-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settings-right {
  height: 100%;
  /* width: calc(100% - 15em); */
  width: 100%;
}

.settings-right .admin {
  min-height: 100%;
  display: flex;
}

.admin-tab-email {
  height: 100%;
  width: 50%;
  margin-right: 1.5em;
  min-width: 18em;
}

.admin-tab-email .admin-card {
  background-color: white;
  box-sizing: border-box;
  padding: 0.5em;
  border: 1px solid black;
  margin-bottom: 2em;
}

.admin-tab-email form {
  margin: 0.5em;
}

.settings-header {
  font-size: 1.5em;
}

.new-member-permissions {
  background-color: rgb(240, 240, 240);
  padding: 0.5em;
  box-sizing: border-box;
  border-radius: 0.5em;
}

.admin-tab-email .top {
  height: calc(100% - 8.5em);
}

.settings-page .organization {
  width: 50%;
  height: 100%;
}

.settings-page textArea {
  height: calc(100% - 7em);
  width: 100%;
}

.settings-page textArea:focus {
  outline-color: black;
}

.settings-page .filter-div {
  margin-bottom: 5px;
}

.org-member-list {
  overflow: scroll;
  /* height: calc(100% - 9em); */
}

.organization .permission-list {
  margin-bottom: 1em;
}
.contact-patient {
  height: 100%;
  width: calc(100% - 12.5em);
  background-color: rgba(50, 50, 50, 0.8);
  /* filter: blur(4px); */
  z-index: 2;
}

.contact-patient-content {
  height: 100%;
  z-index: 3;
}

.contact-patient .bottom {
  position: fixed;
  top: 11em;
  left: 14.5em;
  display: flex;
  /* width: calc(100% - 16em); */
  width: calc(100% - 20em);
  max-width: 60em;
  height: calc(100% - 11em);
}

.contact-patient .left {
  width: 20em;
}


.contact-patient .right {
  display: grid;
  grid-template-rows: min-content min-content auto;
  width: calc(100% - 12em);
  margin-left: 1em;
  height: calc(100% - 6em);
}

.contact-patient .right img {
  height: 1.5em;
  width: auto;
}

.contact-patient textArea {
  margin-top: 1em;
  height: calc(100% - 5em);
  width: 100%;
}

.contact-patient textArea:focus {
  outline-color: black;
}

.contact-patient footer {
  position: absolute;
  /* width: calc(100% - 15.5em); */
  width: calc(100% - 19.5em);
  max-width: 60em;
  display: flex;
  bottom: 0;
  margin: 1.5em;
  left: 12.5em;
}
.test-thumbs-div {
  box-sizing: border-box;
  padding: 0 0 0 1.5em;
  /* width: calc(100% - 38.75em); width of aside + wire-person */
  width: 100%;
  /* overflow-Y: scroll; */
  height: 100%;
  /* display: flex; */
}

.test-thumbs-div > p {
  margin: 0;
}

.test-thumbs {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  margin-right: 1em;
}

.test-thumbs > div {
  margin-bottom: 1em;
}

.test-thumbs > div:last-child {
  margin-bottom: 25em;
}

.moleTracking-image:hover {
  border: 1px solid black;
  box-sizing: border-box;
}
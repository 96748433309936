.floating-action-button, .floating-action-button-x {
  z-index: 7;
  /* position: absolute; */
  position: fixed;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 1em;
  right: 1em;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  background-color: #000000;
  box-shadow: -10px -10px 20px 0 rgba(255,255,255,0.5), 0 5px 11px -1px rgba(191,191,191,0.5), -5px -5px 12px 1px rgba(255,255,255,0.5), 10px 10px 20px 0 rgba(210,210,210,0.5);
}

.floating-action-button-x {
  background-color: white;
  box-shadow: 5px 5px 5px 0 rgba(0,0,0,0.16)
}

.floating-action-button > div {
  z-index: 3;
  cursor: pointer;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background-color: rgb(167, 225, 236);
}

.floating-action-button-x > img {
  width: 75%;
  height: auto;
}
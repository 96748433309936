.add-patient {
  position: relative;
  height: 100%;
}

.add-patient h1 {
  font-weight: 400;
  margin-top: 0;
}

.add-patient main {
  display: flex;
}

.add-image-div {
  width: 20%;
  padding-right: 1.5em;
  box-sizing: border-box;
}

.add-patient .form-div {
  width: 50%;
  display: grid;
  grid-template-rows: max-content auto;
}

.add-patient form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.add-image-div > div {
  background-color: rgb(160, 160, 160);
  width: 100%;
  border-radius: 100%;
}

/* .add-image-div > img {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  cursor: pointer;
} */

.plus-img, .test-request-img {
  /* padding: 2.5em; */
  position: absolute;
  /* width: 100%;
  height: 100%; */
  left: 50%;
  top: 50%;
  /* width: 35%;
  height: 35%; */
  height: 100%;
  width: 100%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  box-sizing: border-box;
  cursor: pointer;
}

.plus-img {
  width: 35%;
  height: 35%;
}

.test-request-img-wrapper {
  /* box-sizing: border-box;
  overflow: auto;
  max-width: 100%; */
  position: relative;
  border-radius: 100%;
  width: 100%;
}

.test-request-img-wrapper:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

/* .test-request-img-wrapper > div {
  width: 100%;
  height: 100%;
  padding-bottom: 0;
} */

.person-img {
  width: 100%;
  height: auto;
  border-radius: 100%;
  box-sizing: border-box;
  cursor: pointer;
  position: absolute;
}

.add-patient .rightCol {
  /* max-height: 43.75em; */
  width: 30%;
  display: grid;
  grid-template-rows: 4em calc(100% - 7em) auto;
  padding-left: 1.5em;
  box-sizing: border-box;
}

.add-patient textarea {
  height: 100%;
  width: 100%;
  position: absolute;
}

.add-patient textarea:focus {
  outline-color: black;
}

.rightCol-top {
  height: 4em;
}

.message-div {
  align-items: center;
}

.sex-input-container {
  position: relative;
  width: 48%;
}

.gender-information-modal {
  position: absolute;
  top: 4.5em;
  width: 100%;
}

p.gender-information {
  margin: 0;
  position: absolute;
  cursor: pointer;
}

.add-patient .text-area-container {
  position: relative;
  height: calc(100% - 5em);
  width: 100%;
}

.add-patient .text-area-overlay {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
}